
import 'whatwg-fetch'

import Action from "../action/Action"

const BookAction = {

  getUnPaidList(token, callback, errorHandler){
    const path =  "/admin/books/unpaid"
    const header = {}
    const body = null
    Action.fetchAuthorizedJson( path, token, "GET", header, body,  callback, errorHandler)
  },



 
}

export default BookAction
