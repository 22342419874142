import React, { useState, useEffect } from 'react'
import {  useParams} from 'react-router-dom';
import { format,differenceInCalendarDays,addDays } from 'date-fns'
import ja from 'date-fns/locale/ja'

import AuthStore from '../../../stores/AuthStore';
import PlanActions from '../../../actions/PlanActions';
import PlanStore from '../../../stores/PlanStore';
import PlanPriceItem from './PlanPriceItem';

import Menu from '../parts/Menu';
import Header from '../parts/Header';
import usePlanState from './plan/usePlanState.tsx';

import "../../../styles/price.css"

function PlanPriceApp(props:PropsValue) {

  const { accommodation_id } = useParams();
  const { plan_id } = useParams();


  const [item, setItem] = useState(null)

  const [list, setList] = useState([])
  const [list_header, setListHeader] = useState([])
  const [list_class, setListClass] = useState("")

  const {getCsv} = usePlanState()


  function planChangeHandler(){
    const plan = PlanStore.getItem()
    setItem(plan)

    var header = [<div className="date">日付</div>]
    setListHeader(header)
    for(var t = 0; t < plan.max_num; t++){
      header.push(<div className="free">{t+1}人</div>)
    }
    const dif = differenceInCalendarDays(  plan.end_at,plan.start_at)

    var days = []
    for(var i = 0 ; i <= dif ; i ++){
      var units = []
      const d = addDays(plan.start_at, i)
      for(var k = 0; k < plan.max_num; k++){
        const price = PlanStore.getPriceObjectFromList(addDays(plan.start_at, i), k + 1)
        if(price){
          units.push(<PlanPriceItem price={price} edit={true} token={AuthStore.getToken()} plan_id={plan_id}/>)
        }
        
      }

      days.push(
        <div className={"mail-list-item week_" + d.getDay()}>
          <div className="date with-week">{format(d, 'yyyy年MM月dd日 (eeeee)',{locale: ja})}</div>
          {units}
        </div>
      )
    }

    setList(days)
    if(plan.max_num > 5){
      setListClass("small")
    }
  }


  useEffect(() => {
    PlanStore.addChangeListener(planChangeHandler)

    PlanActions.getDetail(AuthStore.getToken(),accommodation_id,plan_id)
    return function cleanup() {
        PlanStore.addChangeListener(planChangeHandler)

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[accommodation_id,plan_id]);


  function createByCsv(){
    window.location.href = "/m/accommodation/" + accommodation_id + "/plan/" + plan_id + "/price/csv"
  }

  const createByCreator = () => {
    window.location.href = "/m/accommodation/" + accommodation_id + "/plan/" + plan_id + "/price/creator"
  }

  const getCsvPath = () => {
    getCsv(AuthStore.getToken(), plan_id)
  }

  return(
    <div className="inner">
        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id}/>
          <div className="main-content calendar">
            <Header accommodation_id = {accommodation_id} id="calendar"/>

            <div className="open-close-section">

              <section className={"pannel full "}>
                <div className="section-header">
                    <div className="title">プラン | {item ? item.name : null} | 金額設定 </div>
                    
                    <div className="button-create" onClick={getCsvPath}><div>CSVダウンロード</div></div>
                    <div className="button-create" onClick={createByCsv}><i className="fas fa-plus"></i><div>CSVで更新</div></div>
                    <div className="button-create" onClick={createByCreator}><i className="fas fa-plus"></i><div>新規作成</div></div>
                </div>

                <div className="mail-list-item header">
                  {list_header}
                </div>
                <div className="scrollable-container ">
                  <div className={"scrollable-vertical price " + list_class}>{list}</div>
                </div>


              </section>
            </div>
          </div>

        </main>
    </div>
  )
}

export default PlanPriceApp