import React, { useCallback, useState, useEffect } from 'react'
import { Map } from 'immutable'

import { addDays,format,differenceInCalendarDays,parseISO } from 'date-fns'
import config from 'react-global-configuration';
import '../../../styles/customer.css'
import AuthStore from '../../../stores/AuthStore'
import BookStore from '../../../stores/BookStore'
import RoomStore from '../../../stores/RoomStore'
import PlanStore from '../../../stores/PlanStore'
import ErrorStore from '../../../stores/ErrorStore'

import MailHistoryStore from '../../../stores/MailHistoryStore'
import AccommodationStore from "../../../stores/AccommodationStore"
import BookActions from '../../../actions/BookActions'
import RoomActions from '../../../actions/RoomActions'
import PlanActions from '../../../actions/PlanActions'
import MailHistoryActions from '../../../actions/MailHistoryActions'
import PaymentActions from '../../../actions/PaymentActions'
import Book from '../../../vo/Book'
import BookBase from "./BookBase"
import MessageBox from './MessageBox'
import PaymentCreateBox from './PaymentCreateBox'
import OptionBox from "./OptionBox"
import OptionPaidBox from "./OptionPaidBox"
import CancelBox from './CancelBox'
import RadioBox  from './form/Radiobox'
import MailCreateBox from './MailCreateBox'
import DatePicker,{registerLocale} from "react-datepicker"
import ja from 'date-fns/locale/ja'
import Select from 'react-select'
import { Baloon } from '../../../baloon/Baloon';

function BookItem(props:PropsValue) {

  const [book_item, setItem] = useState(Book())

  const [mainClass, setMainClass] = useState("right")
  const [payhtml, setPayHtml] = useState(null)
  const [rooms, setRooms] = useState([])
  const [plans, setPlans] = useState([])
  const [job, setJob] = useState(null)
  const [mailhtml, setMailHtml] = useState(null)
  const [options, setOptions] = useState([])
  const [boxError, setBoxError] = useState(0)
  const [boxMessage, setBoxMessage] = useState("")
  const [createForm, setCreateForm] = useState(null)
  const [cancelBox, setCancelBox] = useState(null)
  const [boxClass, setBoxClass] = useState("")
  const [mailBox, setMailBox] = useState(null)
  const [is_change, setIsChange] = useState(false)

  const [room_list ,setRoomList] = useState([])
  const [plan_list ,setPlanList] = useState([])
  const [mail, setMail] = useState(null)

  const [end_date, setEndDate] = useState(book_item.end_date)
  registerLocale('ja', ja)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableBookChangeHandler = useCallback(bookChangeHandler, [])

  const accStyles = {
    control: styles => (
      { ...styles,
        borderRadius:'0',
        boxShadow:'none ',
      }
    ),
    
    menu: styles => (
      { ...styles,
        textAlign:"left"
      }
    ),
      
    menuList: styles => (
      { ...styles,
        textAlign:"left"
      }
    ),
    valueContainer: styles => (
      { ...styles,
        textAlign:"left"
      }
    ),

    container: styles => (
      { ...styles,
        backgroundColor: 'white',
        width:'100%',
        fontSize:'.8em'
        
      }
    ),

  };


  function bookChangeHandler(){

    const item = BookStore.getDetail()
    
    setItem(item)
    setMainClass("right")

    //Option
    if(item && item.options){
      setOptions(
        item.options.map(
          option => {
            return   <div className="payment-item-row-wrap">
                      <div className="payment-item-row">
                        <div className="w300 left">  {option.name} x{option.unit} x{option.days}日 ({option.price.toLocaleString()}円) </div>
                        {option.paid_at ? <div>支払済</div> : <div className="link" onClick={() => updateOptionPaid(option)}>未決済</div>}
                        {props.deleteOption ? <div className="delete" onClick={() => deleteOption(option.id)}>削除</div> : null}
                      </div>
                      </div>
          }
        )
      )
    }


    //PAY
    if(item && item.payments){
      console.log(item.payments.length)
    }
    if(item && item.payments && item.payments.length > 0){
      console.log(item.payments.length)
      const items = item.payments.map(
          pa=>{
            return (
              <div className="payment-item-row-wrap">
                <div className="payment-item-row">
                  <div>{pa.payment_exe_date ? format(parseISO(pa.payment_exe_date), 'yyyy年M月d日') : "仮決済"}</div>
                  <div>{pa.amount.toLocaleString()}円</div>
                  <div>{config.get("PAYTYPE_" +pa.pay_type)}</div>
                  {pa.transaction_id !== "" ? null : <div className="delete" onClick={() => deleteItem(pa.id)}>削除</div>}
                </div>
                {
                  pa.transaction_id !== "" ?
                    <div className="payment-item-row payment-id">ID:{pa.transaction_id}</div>
                  :null
                }
            </div>
            )
          }
        )
        //JOB
      console.log(items)
      setPayHtml(<div className="item-content">{items}<div className="button-create-left" onClick={createPayment}><i className="fas fa-plus"></i><div>新規登録</div></div></div>)
    }else{
      setPayHtml(<div className="item-content">未入金<div className="button-create-line" onClick={createPayment}><i className="fas fa-plus"></i><div>新規登録</div></div></div>)
    }
  
    setJob( <Select
      options={config.get("JOBS")}
      styles = {accStyles}
      onChange = {updateJob}
      placeholder = {"職業を選択"}
      defaultValue = {item && item.job ? config.get("JOBS")[item.job] : undefined}
      value = {item && item.job ? config.get("JOBS")[item.job] : undefined}
    />
    )
  }

  const stableErrorChangeHandler = useCallback(errorChangeHandler,[])
  function errorChangeHandler(){
    setBoxError(1)
    setBoxMessage(ErrorStore.getMessage())
    setCreateForm(null)
    setBoxClass("active")
  }


  const stableBookSyncChangeHandler = useCallback(bookSyncChangeHandler,[])
  function bookSyncChangeHandler(){
    setBoxError(0)
    setBoxMessage("同期が完了しました")
    setBoxClass("active")
  }

  const stableBookPaymentChangeHandler = useCallback(bookPaymentChangeHandler,[])
  function bookPaymentChangeHandler(){
    setBoxError(0)
    setBoxMessage("支払いが登録されました。")
    setBoxClass("active")
    setCreateForm(null)
  }

  const stableBookPaymentDeletedChangeHandler = useCallback(bookPaymentDeletedChangeHandler,[])
  function bookPaymentDeletedChangeHandler(){
    setBoxError(0)
    setBoxMessage("支払いが削除されました。")
    setBoxClass("active")
    setCreateForm(null)
  }

  const stableBookDeletedChangeHandler = useCallback(bookDeletedChangeHandler,[])
  function bookDeletedChangeHandler(){
    setBoxMessage(config.get("MESSAGE_BOOK_DELETED"))
    setBoxClass("active")
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableMailHistoryChangeHandler = useCallback(mailHistoryChangeHandler,[])
  function mailHistoryChangeHandler(){
    const itmes = MailHistoryStore.getList().map(item => {
      return ( <div className="payment-item-row-wrap">
        <div className="payment-item-row " >

          <div className="link" onClick={() => setMail(item)}>{item.title.substr(0,27)}...</div>
          <div>{format(new Date(item.created_at), 'yyyy年MM月dd日')}</div>
        </div>
      </div>)
    })

    setMailHtml(<div className="item-content">{itmes}<div className="button-create-left" onClick={createMail}><i className="fas fa-plus"></i><div>メール作成</div></div></div>)
  }

  const stableRoomChangeHandler = useCallback(roomChangeHandler,[])
  function roomChangeHandler(){
    const _room_list = RoomStore.getList().map( item => {
      return ({text:item.name,value:item.id})
    })
    setRoomList(_room_list)
  }


  const stablePlanChangeHandler = useCallback(planChangeHandler,[])
  function planChangeHandler(){
    const _plan_list = PlanStore.getList().map( item => {
      return ({text:item.name,value:item.id})
    })
    setPlanList(_plan_list)
  }

  useEffect(() => {

    BookStore.addChangeListener(stableBookChangeHandler)
    ErrorStore.addChangeListener(stableErrorChangeHandler)
    BookStore.addChangeListener(stableBookSyncChangeHandler,"sync")
    BookStore.addChangeListener(stableBookPaymentChangeHandler,"payment")
    BookStore.addChangeListener(stableBookPaymentDeletedChangeHandler,"payment_deleted")
    BookStore.addChangeListener(stableBookDeletedChangeHandler,"deleted")
    RoomStore.addChangeListener(stableRoomChangeHandler)
    PlanStore.addChangeListener(stablePlanChangeHandler)
    MailHistoryStore.addChangeListener(stableMailHistoryChangeHandler)
    if(props.id > 0){
      BookActions.getDetail(AuthStore.getToken(),props.id)
      MailHistoryActions.getList(AuthStore.getToken(), props.id)
    }else{
      setEndDate(addDays(props.date ? props.date : new Date(),1 ))
      BookActions.setItem(new Book({start_date:props.date ? props.date : new Date(), days:1}))
      
    }

    RoomActions.getList(AuthStore.getToken(),props.accommodation_id )

    if(props.date){
      PlanActions.getList(AuthStore.getToken(),props.accommodation_id,props.date,props.date )
    }else{
      PlanActions.getList(AuthStore.getToken(),props.accommodation_id,new Date(),new Date() )
    }


    setMainClass("right")
    return function cleanup() {
      BookStore.removeChangeListener(stableBookChangeHandler)
      ErrorStore.removeChangeListener(stableErrorChangeHandler)
      BookStore.removeChangeListener(stableBookSyncChangeHandler,"sync")
      BookStore.removeChangeListener(stableBookPaymentChangeHandler,"payment")
      BookStore.removeChangeListener(stableBookPaymentDeletedChangeHandler,"payment_deleted")
      BookStore.removeChangeListener(stableBookDeletedChangeHandler,"deleted")
      RoomStore.removeChangeListener(stableRoomChangeHandler)
      PlanStore.removeChangeListener(stablePlanChangeHandler)
      MailHistoryStore.removeChangeListener(stableMailHistoryChangeHandler)
    };

  },[stableBookChangeHandler,props.id,props.date, stableErrorChangeHandler,stableBookSyncChangeHandler,stableBookPaymentChangeHandler,stableBookPaymentDeletedChangeHandler,stableBookDeletedChangeHandler,stableMailHistoryChangeHandler,stablePlanChangeHandler,stableRoomChangeHandler,props.accommodation_id]);

  useEffect(() => {
    if(room_list.length > 0) {
      setRooms(
          <RadioBox changeHandler={changeFormForRadio} selected={Number(book_item.room_id)} items={room_list} name="room_id" className="column" />
      )
    }

  },[room_list,book_item])

  useEffect(() => {
    if(book_item.id > 0){
      setEndDate(addDays(book_item.start_date,book_item.days ))
    }else{
      const duration = differenceInCalendarDays(end_date , book_item.start_date)
      BookActions.updateForm("days",duration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[book_item.start_date, book_item.days])




  useEffect(() => {

    if(plan_list.length > 0) {
      setPlans(
          <RadioBox changeHandler={changeFormForRadio} selected={Number(book_item.plan_id)} items={plan_list} name="plan_id" className="column"/>
      )
    }else{
      setPlans(null)
    }
  },[plan_list,book_item])

  function createPayment(){
    setCreateForm(<PaymentCreateBox item={BookStore.getDetail()} cancell={closeCreateForm}/>)
  }
  function createMail(){
    setMailBox(<MailCreateBox  item={BookStore.getDetail()} cancell={closeMailForm} accommodation_id={props.accommodation_id}/>)
  }
  
  function changeLimitDateHandler(name, value){
    BookActions.updateForm(name,value)
    
    if(book_item.id > 0){
      BookActions.update(AuthStore.getToken(),book_item.id ,{payment_limit_date:format(value, "yyyy-MM-dd")},true)
    }
  }

  function changeDateHandler(name, value){
    //BookActions.updateForm(name,value)
    const duration = differenceInCalendarDays(end_date , value)
    if(book_item.id > 0){
      BookActions.update(AuthStore.getToken(),book_item.id ,{start_date:format(value, "yyyy-MM-dd"),days:duration},true)
      
    }else{
      PlanActions.getList(AuthStore.getToken(),props.accommodation_id,value,value )
      BookActions.updateForm("start_date",value)
    }
  }

  function changeEndDateHandler(value){
    const start_day = BookStore.getDetail().start_date
    const duration = differenceInCalendarDays( value, start_day)
 
    if(duration > 0){
     //BookActions.updateForm("days",duration)
      if(book_item.id > 0){
        BookActions.update(AuthStore.getToken(),book_item.id ,{ days:duration},true)
      }else{
        setEndDate(value)
        BookActions.updateForm("days",duration)
      }
    }
  }


  function update(name, record){
    if(is_change && book_item.id > 0){
      const item = BookStore.getDetail()
      setIsChange(false)
      BookActions.update(AuthStore.getToken(),item.id , record.set(name,item.get(name)),true)
    }
  }
  function updateNumber(name, record){
    if(is_change && book_item.id > 0){
      const item = BookStore.getDetail()
      setIsChange(false)
      BookActions.update(AuthStore.getToken(),item.id , record.set(name,Number(item.get(name))),true)
    }
  }

  function updateJob(value){
    const item = BookStore.getDetail()
    setIsChange(false)
    BookActions.update(AuthStore.getToken(),item.id , item.set("job",value.value,true))
  
  }

  function changeHandler( e ){
    const target = e.target
    setIsChange(true)
    BookActions.updateForm(target.name,target.value)
  }
  function changeFormForRadio( name, value){
    const _item = BookStore.getDetail()
    if( _item.id > 0){
      if(name === "plan_id"){
        BookActions.updateParts(AuthStore.getToken(),_item.id , {plan_id:Number(value)})
      }else if(name === "room_id"){
        BookActions.updateParts(AuthStore.getToken(),_item.id , {room_id:Number(value)})
      }
    }else{
      BookActions.updateForm(name,value)
    }
  }

  function deleteItem(id){
    PaymentActions.delete(AuthStore.getToken(),id)
  }

  function deleteOption(id){
    props.deleteOption(id)
  }



  function create(){
    //valiadtion
    const item = BookStore.getDetail()

    var error = false
    if(!item.days || item.days <= 0){
      setBoxMessage("宿泊日数が入力されていません")
      error = true
    }else if(!item.person_number || item.person_number < 0){
      setBoxMessage("宿泊人数が入力されていません")
      error = true
    }else if(!item.price){
      setBoxMessage("金額が入力されていません")
      error = true
    }else if(!item.name){
      setBoxMessage("宿泊者名が入力されていません")
      error = true
    }else if(!isFinite(item.person_number)){
      setBoxMessage("宿泊人数が不正です")
      error = true
    }else if(!isFinite(item.price)){
      setBoxMessage("金額が不正です")
      error = true
    }else if(!item.room_id){
      setBoxMessage("部屋タイプを選択してください")
      error = true
    }
    if(error){
      setBoxError(1)
      setCreateForm(null)
      setBoxClass("active")
      return
    }

    BookActions.create(AuthStore.getToken(), item, props.accommodation_id, true)
  }

  function closeCreateForm(){
    setCreateForm(null)
  }

  function closeMessageBox(){
    setBoxClass("close")
  }
  function closeCancelBox(){
    setCancelBox(null)
  }
  function closeMailForm(){
    setMailBox(null)
  }
  function displayCancel(){
    setCancelBox(<CancelBox cancell={closeCancelBox} item={BookStore.getDetail() } />)
  }
  function createOption(){
    const item = BookStore.getDetail()
    setCreateForm(<OptionBox cancell={closeCreateForm} book={item} accommodation_id={props.accommodation_id}/>)
  }

  function updateOptionPaid(option){
    const item = BookStore.getDetail()
    setCreateForm(<OptionPaidBox cancell={closeCreateForm} book_id={item.id} item={option}/>)
  }



  return(
    <div>
    <section className={mainClass + " pannel " + (book_item.delete_flg === 1 ? "deleted-item " : "") }>
      <div className="section-header">
          <div className="title">{book_item.id > 0 ? "予約詳細" : "新規予約"}</div>
          <div className="close" onClick={props.closePannel}><i className="fas fa-times"></i></div>
      </div>
      {book_item.delete_flg === 1 ? <div className="deleted-item-title">削除済みの予約です</div> : null}
      <div className="section-content list form fix">
        <div className="list-item">
          <div className="item-head">メモ</div>
          <div className="item-content"><textarea value={book_item.mini_memo ? book_item.mini_memo : ""} className="full big" name="mini_memo" onChange={changeHandler} onBlur={() => update("mini_memo", Map({mini_memo:null}))} /></div>
        </div>
      </div>
      <div className="section-content list form fix">
        <div className="list-item">
          <div className="item-head">宿泊者名</div>
          <div className="item-content">
            <input type="text" value={book_item.name} name="name" onChange={changeHandler} className="full" onBlur={() => update("name", Map({name:null}))}/>
            { book_item.mail ?  <div className="margin-top-5"><a href={"/m/books/" + book_item.mail}>宿泊履歴を見る</a></div>
            :<div className="margin-top-5">宿泊履歴が表示できません。<Baloon message="メールアドレスの登録がないため、宿泊履歴は表示できません。" /></div>
            }
          </div>
        </div>
        <div className="list-item">
          <div className="item-head">宿泊施設</div>
          <div className="item-content">
          {AccommodationStore.getById(book_item.accommodation_id)
            ? <div className="accommdation-name">
                <div style={{backgroundColor: '#' + AccommodationStore.getById(book_item.accommodation_id).color}} className="acc_icon">
                {AccommodationStore.getById(book_item.accommodation_id).short_name}
                </div>
                {AccommodationStore.getById(book_item.accommodation_id).name}
                </div>
            : ""}

          </div>
        </div>
        <div className="list-item no-top-margin">
          <div className="item-head">プラン </div>
          <div className="item-content">
            {plans}
          </div>
        </div>
        <div className="list-item no-top-margin">
          <div className="item-head">部屋 </div>
          <div className="item-content">
            {rooms}
          </div>
        </div>
        <div className="list-item no-bottom-margin">
          <div className="item-head">チェックイン日</div>
          <div className="item-content">

            <DatePicker showPopperArrow={false} locale="ja" placeholderText="宿泊日" dateFormat="yyyy-MM-dd" selected={ book_item.start_date} onChange={date => changeDateHandler("start_date", date)} />
          </div>
        </div>
        <div className="list-item no-margin">
          <div className="item-head">宿泊数</div>
          <div className="item-content indent">
          {book_item.days}&nbsp;泊
          </div>
        </div>

        <div className="list-item no-top-margin">
          <div className="item-head">チェックアウト日 </div>
          <div className="item-content">
            <DatePicker showPopperArrow={false} locale="ja" placeholderText="宿泊日" dateFormat="yyyy-MM-dd" selected={end_date} onChange={date => changeEndDateHandler(date)} />
          </div>
        </div>
        <div className="list-item">
          <div className="item-head">宿泊人数</div>
          <div className="item-content"><input type="text" value={book_item.person_number} className="small" name="person_number" onChange={changeHandler} onBlur={() => updateNumber("person_number", Map({person_number:null}))}  /></div>
        </div>
        <div className="list-item">
          <div className="item-head">金額</div>
          <div className="item-content"><input type="text" value={book_item.price} className="small" name="price" onChange={changeHandler} onBlur={() => updateNumber("price", Map({price:null}))} /></div>
        </div>
        <div className="list-item">
          <div className="item-head">トタール金額</div>
          <div className="item-content">{book_item.price_sub ? book_item.price_sub.toLocaleString() + "円" : null}</div>
        </div>

        <div className="list-item">
          <div className="item-head">メールアドレス</div>
          <div className="item-content"><input type="text" value={book_item.mail} className="full" name="mail" onChange={changeHandler} onBlur={() => update("mail", Map({mail:null}))}/></div>
        </div>
        <div className="list-item">
          <div className="item-head">住所</div>
          <div className="item-content"><input type="text" value={book_item.address} className="full" name="address" onChange={changeHandler} onBlur={() => update("address", Map({address:null}))} /></div>
        </div>
        <div className="list-item">
          <div className="item-head">電話番号</div>
          <div className="item-content"><input type="text" value={book_item.tel} className="small" name="tel" onChange={changeHandler} onBlur={() => update("tel", Map({tel:null}))} /></div>
        </div>

        <div className="list-item">
          <div className="item-head">職業</div>
          <div className="item-content">
            {job}
          </div>
        </div>

        {book_item.id > 0 ?
        <div className="list-item">
          <div className="item-head">オプション</div>
          <div className="item-content">
            {options}
            <div className="button-create-left" onClick={createOption}><i className="fas fa-plus"></i>&nbsp;オプション追加</div>
          </div>

        </div>
        : null}

        {book_item.id > 0 ?
        <div className="list-item">
          <div className="item-head">宿への連絡事項</div>
          <div className="item-content"><textarea value={book_item.memo ? book_item.memo : ""} className="full tall" /></div>
        </div>
        : null}
        {book_item.id > 0 ?
        <div className="list-item">
          <div className="item-head">予約サイト</div>
          <div className="item-content">{config.get("VIA_" + book_item.via)}</div>
        </div>
        : null}
        {book_item.id > 0 ?
          <div className="list-item">
            <div className="item-head">予約発生日</div>
            <div className="item-content">{format(book_item.created_at, 'yyyy年M月d日')}</div>
          </div>
        : null}
          <div className="list-item">
          <div className="item-head">振り込み期限</div>
          <div className="item-content">
            <DatePicker showPopperArrow={false} locale="ja" placeholderText="振り込み期限" dateFormat="yyyy-MM-dd" selected={new Date(book_item.payment_limit_date)} onChange={date => changeLimitDateHandler("payment_limit_date", date)} />
          </div>
        </div>
        {
          book_item.uid && AccommodationStore.getById(book_item.accommodation_id)
          ?
            <div className="list-item">
              <div className="item-head">ユーザーURL</div>
              <div className="item-content sml"><a target="_blank" rel="noopener noreferrer" href={ AccommodationStore.getById(book_item.accommodation_id).detail_url + book_item.uid}>{ AccommodationStore.getById(book_item.accommodation_id).detail_url + book_item.uid}</a></div>
            </div>
          : null
        }

        {book_item.id > 0 ?
        <div className="list-item">
          <div className="item-head">確認番号</div>
            { book_item.uid.substr(0,3)}
        </div>
        : null}

        {book_item.id > 0 ?
        <div className="list-item">
          <div className="item-head">支払い</div>
            {payhtml}
        </div>
        : null}

        {book_item.id > 0 ?
        <div className="list-item">
          <div className="item-head">メール送信履歴</div>
            {mailhtml}

        </div>
        : null}
      </div>
      {book_item.id === 0  ||  book_item.delete_flg === 1 ? null  :
        <div className="section-content  button">
          <button className="white-btn midium" onClick={displayCancel}>予約をキャンセル</button>
        </div>
      }
      {book_item.id === 0 ?
        <div className="section-content  button">
          <button className="create-btn midium" onClick={create}>予約を作成</button>
        </div>
      : null
      }

    </section>
    {createForm}
    {cancelBox}
    {mailBox}
    <BookBase mail={mail} />
    <MessageBox message={boxMessage} error={boxError} main_class={boxClass} closeMessageBox={closeMessageBox}/>
    </div>

  )
}

export default BookItem
