



import React, { useState, useEffect } from 'react'


import useBookState from './useBookState.tsx';
import useAuthState from "../auth/useAuthState.js"


function UnPaidBook(props:PropsValue) {

  const [list, setList] = useState(null)

  const  {book_list,book_list_updated_at, getUpPaidList} = useBookState()
  const {token,getToken} = useAuthState()


  useEffect(() => {

    if(book_list_updated_at && book_list){
      setList(book_list.map(item => <tr><td>{item.name}</td></tr>))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[book_list_updated_at]);

  useEffect(() => {

    if(token){
      getUpPaidList(token)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);


  useEffect(() => {
    getToken()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);




  return(
   
    <div > {list ?
      <div className="toggle-pannel-header">
          <div className="toggle-title">未入金公式予約（24時間経過）</div>
      </div>
      :null}

    {list ?
      <table>
        <tr><th>名前</th></tr>
        {list}
      </table>
      :null}
    </div>
    
  )
}

export default UnPaidBook
