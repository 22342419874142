import { useState } from 'react';
import BookAction from './BookAction.js';
import ResponseData from '../../../action/ResponseData';




function useBookState() {
  const [list, setList] = useState(null)
  const [error, setError] = useState(null)
  const [updated_at, setUpdatedAt] = useState<Date | null>(null)


  const loadHandler = (data:ResponseData) => {
    if(data.data){
      setList(data.data.books)
      setUpdatedAt(new Date())
    }
    
   
  }
  const errorHandler = (data:ResponseData) => {
    setError(data.error)
  }

  const getUpPaidList = (token:string) => {

    BookAction.getUnPaidList(token, loadHandler, errorHandler)
  } 

  

  return { price_error:error, book_list_updated_at:updated_at, book_list:list, getUpPaidList}

}

export default useBookState