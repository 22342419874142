
import 'whatwg-fetch'

import Action from '../../../../actions/Action'

const PlanAction = {


  get(token, accommodation_id, plan_id,  callback, errorHandler){
    const path =  "/admin/accommodations/" + accommodation_id + "/plans/" + plan_id
    const header = {}
    const body = null
    Action.fetchAuthorizedJson( path, token, "GET", header, body,  callback, errorHandler)
  },


  getCsv(token,  plan_id){
    const path =  "/admin/plans/" + plan_id + "/schedules?format=csv"
    const header = {}
    Action.fetchCsv( path, token, "plan_" + plan_id, header)
  },



 
}

export default PlanAction
