import { useState } from 'react';
import PlanAction from './PlanAction';
import Plan from './Plan.tsx';
import ResponseData from '../../../../actions/ResponseData.js';
import parseISO from 'date-fns/parseISO';

type PlanJson = { 
  id:string | null 
  name:string | null 
  start_at:string | null 
  end_at:string | null 
  max_num:string | null 
};




function usePlanState() {

  const [error, setError] = useState(null)
  const [plan, setPlan] = useState<Plan | null>(null)

  const loadHandler = (data:ResponseData) => {
    if(data.data){
      const p:PlanJson = data.data
      setPlan(
        new Plan(
          {id:p.id, name: p.name, start_at:p.start_at ? new Date(parseISO(p.start_at) ): null , end_at: p.end_at ?  new Date(p.end_at) : null, max_num:Number(p.max_num)}
        )
      )
    }
  }

  const errorHandler = (data:ResponseData) => {
    setError(data.error)
  }


 
  const get = (token:string, accommodation_id:number, plan_id:number) => {
    PlanAction.get(token, accommodation_id, plan_id, loadHandler, errorHandler)
  } 

  const getCsv= (token:string, plan_id:number) => {
    PlanAction.getCsv(token,  plan_id)
  } 

  

  return { plan, error,  getPlan:get, getCsv}

}

export default usePlanState