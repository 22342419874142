import React, { useState,useEffect,useCallback } from 'react'
import { format,setDate,subMonths,getDay,subDays,addDays,differenceInCalendarWeeks,isSameDay,getYear,getMonth,getDate } from 'date-fns'
import config from 'react-global-configuration';

import CalendarActions from "../../../actions/CalendarActions"
import CalendarStore from "../../../stores/CalendarStore"
import AccommodationStore from "../../../stores/AccommodationStore"
import BookStore from "../../../stores/BookStore"
import AuthStore from "../../../stores/AuthStore"
import RoomStore from "../../../stores/RoomStore"
import "../../../styles/calendar.css"


function Calendar(props:PropsValue) {

  const [calendar_content , setCalendarContent] = useState([]);
  const [upper_array , setUpperArray] = useState([]);
  const [bottom_array , setBottomArray] = useState([]);
  const [first_flg, setFirstFlg] = useState(false)
  const [current_first_date, setFirstDate] = useState(null)
  const [current_last_date, setLastDate] = useState(null)
  const [is_updating, setUpdateing] = useState(false)
  const [is_updating_bottom, setUpdateingBottom] = useState(false)
  const [is_loading, setIsLoading] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableCreateColTag = useCallback(createColTag,[])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableBookChangeHandler = useCallback(bookChangeHandler,[])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableRoomChangeHandler = useCallback(bookChangeHandler, [])

  function bookChangeHandler(){
    setIsLoading(false)
    //console.log("bookChangeHandler")
    const target_date = CalendarStore.getCurrentDate()
    if(target_date){

      var first_day = target_date.start_at
      const start_dif = getDay(first_day)
      first_day = subDays(first_day,start_dif + 1)

      var counter = 0;
      if(differenceInCalendarWeeks(target_date.end_at,first_day)){
        const new_array = (new Array(differenceInCalendarWeeks(target_date.end_at,first_day))).fill(null).map( i => {
            return <div className="week">
              {(new Array(7)).fill(null).map( t => {
                counter += 1
                return createColTag(addDays(first_day, counter))
              })}
            </div>
        })

        setCalendarContent(new_array )
      }

    }

  }


  //ADD ON UPPER
  const stableCalendarCreateUpperChangeHandler = useCallback(calendarCreateUpperChangeHandler, [])
  function calendarCreateUpperChangeHandler(){
    setUpdateing(true)
  }


  useEffect(() => {
    if(is_updating){
      const first_date = subDays(current_first_date, 7 *  config.get("CALENDAR_ADD_WEEKS"))
      var counter = 0;
      const new_arry = (new Array( config.get("CALENDAR_ADD_WEEKS"))).fill(null).map( i => {
          return <div className="week">
            {(new Array(7)).fill(null).map( t => {
              counter += 1
              return stableCreateColTag(addDays(first_date, counter))
            })}
          </div>
      })
      setFirstDate(first_date)
      setUpperArray(new_arry)
      setUpdateing(false)

      if(CalendarStore.getCurrentDate() && !is_loading){
        setIsLoading(true)
        CalendarActions.setCurrentDate(AuthStore.getToken(), {start_at:first_date, end_at:CalendarStore.getCurrentDate().end_at}, props.accommodation_id)
      }
    }

  }, [is_updating,is_loading,current_first_date,props.accommodation_id,stableCreateColTag])


  useEffect(() => {
    if(upper_array.length > 0){
      setCalendarContent([upper_array, ...calendar_content])
      setUpperArray([])
      const today = new Date()
      if(document.getElementById("calendar_" + format(today,'yyyy_MM_dd'))) {
        const h = document.getElementById("calendar_" + format(today,'yyyy_MM_dd')).offsetHeight
        document.getElementById("time_line").scrollTop  += h * config.get("CALENDAR_ADD_WEEKS")
      }
      CalendarActions.createdUpper()
    }
  },[upper_array,calendar_content])


  ///ADD UNDER BOTTOM
  const stableCalendarCreateBottomChangeHandler = useCallback(calendarCreateBottomChangeHandler, [])
  function calendarCreateBottomChangeHandler(){
    setUpdateingBottom(true)
  }

  useEffect(() => {
    if(is_updating_bottom){
      const first_date = current_last_date
      var counter = 0;
      const new_arry = (new Array( config.get("CALENDAR_ADD_WEEKS"))).fill(null).map( i => {
          return <div className="week">
            {(new Array(7)).fill(null).map( t => {
              counter += 1
              return stableCreateColTag(addDays(first_date,counter))
            })}
          </div>
      })
      setLastDate(addDays(first_date, counter))
      if(CalendarStore.getCurrentDate() && !is_loading){
        setIsLoading(true)
        CalendarActions.setCurrentDate(AuthStore.getToken(), {start_at:CalendarStore.getCurrentDate().start_at, end_at:addDays(first_date, counter)}, props.accommodation_id)
      }

      //CalendarActions.setCurrentDate({start_at:Cale})
      setBottomArray(new_arry)
      setUpdateingBottom(false)
    }
  }, [is_updating_bottom,current_last_date,is_loading,props.accommodation_id,stableCreateColTag])

  useEffect(() => {
    if(bottom_array.length > 0){
      setCalendarContent([...calendar_content, bottom_array])
      setBottomArray([])
      CalendarActions.createdBottom()
    }
  },[bottom_array,calendar_content])


  useEffect(() => {
    CalendarStore.addChangeListener(stableCalendarCreateUpperChangeHandler,"create_upper")
    CalendarStore.addChangeListener(stableCalendarCreateBottomChangeHandler,"create_bottom")
    BookStore.addChangeListener(stableBookChangeHandler)
    RoomStore.addChangeListener(stableRoomChangeHandler)
    return function cleanup() {
      BookStore.removeChangeListener(stableBookChangeHandler)
      RoomStore.removeChangeListener(stableRoomChangeHandler)
      CalendarStore.removeChangeListener(stableCalendarCreateUpperChangeHandler,"create_upper")
      CalendarStore.removeChangeListener(stableCalendarCreateBottomChangeHandler,"create_bottom")
    };
  },[stableCalendarCreateBottomChangeHandler,stableCalendarCreateUpperChangeHandler,stableBookChangeHandler,stableRoomChangeHandler]);


  //First Calendar
  useEffect(() => {
    const today = new Date()
    var first_day = setDate(subMonths(today,1), 1) ;
    const start_dif = getDay(first_day)
    first_day = subDays(first_day,start_dif + 1)

    var counter = 0;
    const new_array = (new Array(15)).fill(null).map( i => {
        return <div className="week">
          {(new Array(7)).fill(null).map( t => {
            counter += 1
            return stableCreateColTag(addDays(first_day, counter))
          })}
        </div>
      })
    setCalendarContent(new_array )
    CalendarActions.changeCurrentMonth(setDate(today,1))
    setFirstDate(first_day)
    setLastDate(addDays(first_day, counter))
    CalendarActions.setCurrentDate(AuthStore.getToken(), {start_at:first_day, end_at:addDays(first_day, counter)}, props.accommodation_id)
  },[props.accommodation_id,stableCreateColTag]);

  useEffect(() => {
    const today = new Date()
    if(document.getElementById("calendar_" + format(today,'yyyy_MM_dd'))) {
      if(!first_flg){
        document.getElementById("calendar_" + format(subDays(today,7),'yyyy_MM_dd')).scrollIntoView(true)
        setFirstFlg(true)
      }
    }
  },[calendar_content,first_flg])


  //Create Common Tag

  function createColTag(target_day, selected_id){

    if(RoomStore.getList().length > 0 ){
      const bookings = BookStore.getDataFromDate(target_day)

      const items = bookings.map( item => {
        const op_icons = item.options.map(op => {
          if(op.icon ) {
            return  <i className={"red " +op.icon}>&nbsp;</i>
          }
          else { return null}
        })
        return <div className="book" onClick={() => openPannel(item.id, target_day)}>{item.plan_icon ? <i className={"red " +item.plan_icon}>&nbsp;</i>:null}{op_icons}{item.name}</div>
      })
        var add_class = ""
      if(isSameDay(target_day,new Date())) add_class += "today "


      if(bookings.length > 0){
        const is_no_room_over_book = RoomStore.checkOverBook(bookings)
        const accomodation = AccommodationStore.getById(props.accommodation_id)
        if(accomodation){
          if(bookings.length > accomodation.room_number || !is_no_room_over_book ){
            add_class += "over "
          }else if(bookings.length === accomodation.room_number  ){
            add_class += "full "
          }else{
            add_class += "has-book "
          }
        }
       
      }
      if(BookStore.getDetail() && BookStore.getDetail().id){
        if(items.length === 0){
          add_class += " unselect "
        }else{
          var selected = false
          for(var i =0 ;  i < bookings.length; i++){
            if(bookings[i].id === BookStore.getDetail().id) selected = true
          }
          if(!selected ) add_class += " unselect "
        }
      }
      return <div className={"date " + add_class } id={"calendar_" + format(target_day, 'yyyy_MM_dd')} key={"calendar_" +format(target_day, 'yyyy_MM_dd')}>
          <div className="calendar-date" onClick={() => gotoBookList(target_day)}>{format(target_day, 'M/d')}</div>{items}
        </div>
    }else{
      return null
    }
  }

  function openPannel(id,target_date){
    props.openPannel(id,target_date)
  }

  function gotoBookList(target_date){
    window.location.href = "/m/accommodation/" +  props.accommodation_id + "/book/" + getYear(target_date) + "/" + (getMonth(target_date) + 1)+ "/" + getDate(target_date)
  }
  return(

    <section className={"pannel " + props.pannelCss}>
      <div className="section-header">

        <div className="current-month">{props.current_month ? format(props.current_month,"yyyy年MM月") : null}</div>

        <div className="right-menu">
          <div className=" " onClick={props.changeCalendar}><i className="fas fa-list"></i>&nbsp;縦表示</div>
          <div className="inline-link" onClick={props.createNew}><i className="fas fa-plus-circle"></i> 新規予約作成</div>
        </div>
      </div>
      <div className="mail-list-item header week">
        <div className={"date"}>日</div>
        <div className={"date"}>月</div>
        <div className={"date"}>火</div>
        <div className={"date"}>水</div>
        <div className={"date"}>木</div>
        <div className={"date"}>金</div>
        <div className={"date"}>土</div>
      </div>
      <div className="free-pannel scrollable-vertical" id="time_line" onScroll={props.handleScroll}>
        <div>
          <div className="calendar-monthly" id="calendar">
            {calendar_content}
          </div>
        </div>
      </div>
    </section>



  )
}

export default Calendar
