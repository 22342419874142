import React, { useState, useEffect } from 'react'
import AccommodationActions from '../../../actions/AccommodationActions';
import AuthActions from '../../../actions/AuthActions';
import AuthStore from '../../../stores/AuthStore'
import AccommodationStore from '../../../stores/AccommodationStore'
import '../../../styles/menu.css'
import "../../../styles/color.css"
function Menu(props:PropsValue) {

  const [accommodation_list, setAccommodation] = useState([]);

  useEffect(() => {
    function accommodationChangeHandler(){
      setAccommodation(
        AccommodationStore.getList().map( item =>{
          return <div className={"accommodation_list_item " + (Number(props.accommodation_id) ===  Number(item.id) ? "selected" : "")} key={"acc_" + item.id} onClick={() => linkToAccommdation(item.id)}>
            <div style={{backgroundColor: '#' + item.color}} className={"acc_icon acc_" + (item.color ? item.color : "c10")}>{item.short_name}</div>{item.name}
          </div>
        })
      )
    }
    AccommodationStore.addChangeListener(accommodationChangeHandler)
    AuthActions.setAuth()

    AccommodationActions.getList(AuthStore.getToken(), props.callback)
    return function cleanup() {
      AccommodationStore.removeChangeListener(accommodationChangeHandler)
    };

  },[props.accommodation_id,props.callback]);

  function linkToAccommdation(id){
    window.location.href = "/m/accommodation/" + id
  }



  return(
    <div className="sidebox">
      <div className="logo"><img src="/images/logo_c.svg" alt="logo"/></div>
     
      <section className="menu">
        <div className={props.selected === "index" ? "selected" : ""}><a href="/m"><i className="fas fa-home"></i> ホーム</a></div>
        <div className={props.selected === "user" ? "selected" : ""}><a href="/m/user"><i className="fas fa-user"></i> ユーザー管理</a></div>
        <div className={props.selected === "supplier" ? "selected" : ""}><a href="/m/supplier"><i className="far fa-handshake"></i> パートナー</a></div>
        <div className={props.selected === "mail" ? "selected" : ""}><a href="/m/mail"><i className="fas fa-paper-plane"></i> 一斉メール</a></div>
        <div className={props.selected === "analytics" ? "selected" : ""}><a href="/m/analitcs"><i className="fas fa-chart-line"></i> 宿泊者分析</a></div>
      </section>
      <section className="accommodation_menu">
        {accommodation_list}
      </section>
      <div className="center font-size-8 w-full mt-10">version 2.0.1</div>
    </div>
  )
}

export default Menu
//  <div className={props.selected === "timesheet" ? "selected" : ""}><a href="/m/timesheet"><i className="fas fa-calendar-alt"></i> シフト管理</a></div>
