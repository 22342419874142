import React, { useState, useEffect,useCallback } from 'react'
import {  useParams} from 'react-router-dom';

import config from 'react-global-configuration';

import AuthStore from '../../../stores/AuthStore';
import SyncSettingActions from '../../../actions/SyncSettingActions';
import SyncSettingStore from '../../../stores/SyncSettingStore';
import AccommodationStore from '../../../stores/AccommodationStore';


import Menu from '../parts/Menu';
import Header from '../parts/Header';




function SettingApp(props:PropsValue) {

  const { accommodation_id } = useParams();
  const [ikyu, setIkyu] = useState(null)
  const [accommodation, setAcccomodation] = useState(null)

  const stableSyncSettingChangeHandler = useCallback(syncSettingChangeHandler, [])

  useEffect(() => {
    AccommodationStore.addChangeListener(accommodationUpdateHandler)
    SyncSettingStore.addChangeListener(stableSyncSettingChangeHandler)
    SyncSettingActions.getList(AuthStore.getToken(),accommodation_id)
    return function cleanup() {
        SyncSettingStore.removeChangeListener(stableSyncSettingChangeHandler)
        AccommodationStore.removeChangeListener(accommodationUpdateHandler)
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[stableSyncSettingChangeHandler,accommodation_id]);


  function accommodationUpdateHandler(){
    setAcccomodation(AccommodationStore.getById(accommodation_id))
  }

  function syncSettingChangeHandler(){
    setIkyu(SyncSettingStore.getByServiceId(config.get("SERVICE_ID_IKYU")))
  }

  function IkyuHandler(e){
    const target = e.target
    SyncSettingActions.updateItem(Number(config.get("SERVICE_ID_IKYU")), target.name,target.value)
  }
  function updateIkyu(){
    const item = SyncSettingStore.getByServiceId(config.get("SERVICE_ID_IKYU"))
    SyncSettingActions.update(AuthStore.getToken(),accommodation_id,item.id, item)
  }

  const copy = (url) => {
    navigator.clipboard.writeText(url).then(  () => {}, () => {});
  }

  return(
    <div className="inner">
        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id} />
          <div className="main-content calendar">
            <Header accommodation_id = {accommodation_id} id="calendar"/>

            <div className="free-pannel scrollable-vertical">
              <section className="pannel">
                <div className="section-header">
                    <div className="title">詳細設定</div>
                </div>

                <div className="section-content list form fix">
                <div className="list-item">
                    <div className="item-head">iCalender</div>
                    <div className="item-content">
                      {accommodation ? 
                        <div className="setting-form-item"><label>URL</label><div><a href={config.get("API_PATH") + "/ical/" + accommodation.uid + ".ics"} rel="noreferrer" target="_blank">{config.get("API_PATH") + "/ical/" + accommodation.uid + ".ics"}</a>&nbsp; &nbsp;<span className="link" onClick={() => copy(config.get("API_PATH") + "/ical/" + accommodation.uid + ".ics")}>[コピー]</span> </div></div>
                        :null }
                    </div>
                  </div>


                  <div className="list-item">
                    <div className="item-head">一休</div>
                    <div className="item-content column">
                        <div className="flex-row mb-10">
                          <div className="setting-form-item"><label>施設ID</label><input type="text" name="accommodation_id" value={ikyu  && ikyu.params ? ikyu.params.accommodation_id : ""} onChange={IkyuHandler} placeholder="施設ID" onBlur={updateIkyu}/></div>
                          <div className="setting-form-item"><label>オペレータID</label><input type="text" name="id" onChange={IkyuHandler} onBlur={updateIkyu} value={ikyu  && ikyu.params ? ikyu.params.id : ""} placeholder="オペレータID"/></div>
                          <div className="setting-form-item"><label>パスワード</label><input type="text" name="password" onChange={IkyuHandler} onBlur={updateIkyu} value={ikyu  && ikyu.params ? ikyu.params.password : ""} placeholder="パスワード"/></div>
                        </div>
                        <div ><span className="font-size-8">管理ページ :</span> <a  rel="noreferrer" target="_blank" href="https://www.ikyu.com/accommodation/ap/AsfW10101.aspx">https://www.ikyu.com/accommodation/ap/AsfW10101.aspx</a></div>
                    </div>
                    
                  </div>
                  
                </div>

              </section>
            </div>

          </div>

        </main>
    </div>
  )
}

export default SettingApp
